import React from "react"
import { Box, Grid, Typography, Container, Stack } from "@mui/material"
import { colors, Textarea } from "@parallel-domain/pd-theme"
import parse from "html-react-parser"

// import app components
import { Headline } from "../../../submodules/components/headline"
import { Image } from "../../../submodules/components/image"

const TextImageColumns = props => {
  const {
    headline,
    subline,
    textstyle = "option1",
    image,
    columnsdesktop,
    columnsmobile,
    items,
  } = props

  return (
    <Box sx={{ py: [5] }}>
      <Container maxWidth="lg">
        {(subline || headline || image.desktop || image.mobile) && (
          <Grid container spacing={6} sx={{ mb: 6 }}>
            <Grid
              item
              xs={12}
              sm={6}
              md={textstyle === "option1" ? 4 : 5}
              sx={{ mb: [2, 0], order: { sm: 2 } }}
            >
              <Box
                sx={{
                  position: "relative",
                  zIndex: 5,
                  borderRadius: "1rem",
                  overflow: "hidden",
                }}
              >
                {image && <Image {...image} />}
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={textstyle === "option1" ? 8 : 7}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Stack spacing={2}>
                {textstyle === "option1" ? (
                  <>
                    {subline && (
                      <Typography variant="subtitle2" color="primary" component="div">
                        {parse(subline)}
                      </Typography>
                    )}

                    {headline && (
                      <Typography variant="h2" sx={{ pr: { sm: 3 } }}>
                        {parse(headline)}
                      </Typography>
                    )}
                  </>
                ) : (
                  <>
                    {subline && (
                      <Typography
                        variant="h2"
                        sx={{ color: colors.tertiary[700] }}
                      >
                        {parse(subline)}
                      </Typography>
                    )}

                    {headline && (
                      <Typography variant="body1" sx={{ pr: { sm: 3 } }}>
                        {parse(headline)}
                      </Typography>
                    )}
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={6}>
          {items &&
            items.map((o, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={12 / columnsmobile}
                  md={12 / Math.ceil((columnsmobile + columnsdesktop) / 2)}
                  lg={12 / columnsdesktop}
                >
                  {o?.icon?.svg && (
                    <Box
                      sx={{
                        height: "50px",
                        width: "50px",
                        mb: 2,
                        svg: { height: "auto", maxHeight: "100%" },
                      }}
                    >
                      {parse(o.icon.svg)}
                    </Box>
                  )}

                  {o.title && (
                    <Headline {...o.title} delay={0.1 * index} sx={{ mb: 2 }} />
                  )}

                  {o.text && <Textarea p="body2">{o.text}</Textarea>}
                </Grid>
              )
            })}
        </Grid>
      </Container>
    </Box>
  )
}

export default TextImageColumns
